import React, { useContext } from 'react';
import get from 'lodash/get'
import at from 'lodash/at';
import { Button } from 'crux';
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import styled, { withTheme } from 'styled-components';
import {
  getEnableContinueBreadCrumb,
  getLoaders,
  getContinueState,
} from '../../redux/selector/validations';
import {
  getNextPage,
  submitAuthQuestion,
  submitSectionQuestion,
  goToPreviousBreadCrumb,
} from '../../redux/actions/questions';
import {
  addListItem,
  submitListAnswers,
} from '../../redux/actions/listQuestion';
import {
  getBreadCrumbInfo,
  getCurrentQuestionSet,
} from '../../redux/selector/questions';
import {
  singleButtonPage,
  buttonLabelMap,
  scrollTop,
  toDashCase,
} from '../../utils';
import { getListProps } from '../../redux/selector/listQuestion';
import { getCSSVariables } from '../../cruxComponents/utils';
import { ListContext } from '../List/ListContext';

const ButtonWrapper = styled.div`
	margin-left: 8px;
  margin-top: 50px;
  @media (max-width: 767px) {
   margin-top: 0px;
  }
`;

const ListButtonWrapper = styled.div`
  ${({ disabled }) => disabled && '> button { cursor: not-allowed; }'}
`;

const FullWidthButton = styled.div`
  display: flex;
  button[name='styled-button']{
    height: 50px;
  }
  ${({ fullWidthBtnTheme }) => fullWidthBtnTheme || 'flex-direction: row;'}
  width: ${({ fullWidthBtnTheme }) => fullWidthBtnTheme ? fullWidthBtnTheme.containerWidth : '100%' };
  max-width: ${({ fullWidthBtnTheme }) => fullWidthBtnTheme ? fullWidthBtnTheme.containerMaxWidth : ''};
	& > button {
    width: ${({ fullWidthBtnTheme }) => fullWidthBtnTheme ? fullWidthBtnTheme.width : '100%' };
		max-width: 560px;
	}
  justify-content: ${({ fullWidthBtnTheme }) => fullWidthBtnTheme ? fullWidthBtnTheme.justify : 'center' };
  ${({ fullWidthBtnTheme }) => fullWidthBtnTheme && `margin: ${fullWidthBtnTheme.margin}` };
  @media (max-width: 767px) {
    max-width: 100%;
    & > button {
      width: 100%;
    }
  }
`;

const SubSectionButton = styled.div`
  display: flex;
  position:relative;
  flex-direction:column;
  align-items:flex-start;
  // padding-left: 20px;
  // flex: ${({ isUpdateMode }) => (isUpdateMode ? 4 : 'none')};
  flex: 'none';
  ${props => props.showInlineButtons && 'flex:none;'};
  width: 240px;
  @media (max-width: 767px) {
    // align-items: normal;
    // padding: 0;
    // width: 100%;
    // margin-bottom: 10px;
  }
  > button:focus {
    background-color: ${ ({ themeConfiguration }) => themeConfiguration.hover.selectedBgColor};
    color: ${ ({ themeConfiguration }) => themeConfiguration.hover.bgColor};
    outline: none;
  }
`;

const PageButton = styled.div`
  max-width: 780px;
  width: 90%;
  margin: 0 auto;
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft}`};
  padding: 10px;
  ${(props) => props.cardPadding && `padding: ${props.cardPadding}; padding-top: 10px; padding-bottom: 10px`};
  ${(props) => props.mergeActionPageButtons && 'margin-top: -40px; padding-top: 0px'};
  ${(props) => !props.showInlineButtons ? 'background: #ffffff' : 'padding-right: 0px'};
  justify-content: flex-end;
  ${({ showInlineButtons , pageLayout, theme:{ pageId } }) => (showInlineButtons||pageId==='challenge') && pageLayout === 'single' && 'width: 80%; max-width: 80%'};
  border-radius: 0px;
  display: flex;
  ${({ maxWidth }) => maxWidth &&`
  max-width: ${maxWidth};
  `}
   @media (max-width: 767px) {
    padding: 0;
    margin: 0;
    ${(props) => props.cardPadding && `padding: ${props.cardPadding}; padding-top: 10px; padding-bottom: 10px`};
    ${(props) => props.mergeActionPageButtons && 'margin-top: -40px; padding-top: 0px'};
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  ${({ theme:{ pageId }  }) => pageId === 'signature' && `
  @media (min-width: 7680px) {
    max-width: 20%;
    & > button {
      width: 20%;
    }
  }

  @media (min-width: 5760px) and (max-width: 7659px){
    max-width: 26%;
    & > button {
      width: 26%;
    }
  }

  @media (min-width: 4398px) and (max-width: 5759px){
    max-width: 34%;
    & > button {
      width: 34%;
    }
  }

  @media (min-width: 3840px) and (max-width: 4397px){
    max-width: 40%;
    & > button {
      width: 40%;
    }
  }

  @media (min-width: 2880px) and (max-width: 3839px){
    max-width: 52%;
    & > button {
      width: 52%;
    }
  }

  @media (min-width: 2560px) and (max-width: 2799px){
    max-width: 59%;
    & > button {
      width: 59%;
    }
  }

  @media (min-width: 2400px) and (max-width: 2559px){
    max-width: 63%;
    & > button {
      width: 63%;
    }
  }

  @media (min-width: 2199px) and (max-width: 2399px){
    max-width: 69%;
    & > button {
      width: 69%;
    }
  }

  @media (min-width: 2133px) and (max-width: 2198px){
    max-width: 71%;
    & > button {
      width: 71%;
    }
  }

  @media (min-width: 1954px) and (max-width: 2047px){
    max-width: 78%;
    & > button {
      width: 78%;
    }
  }
`
}
`;

const SubSection = styled.div`
  display: flex;
  position:relative;
  flex-direction:row;
  align-items:flex-end;
  button[name='styled-button']{
    height: 50px;
  }
  ${(props) => !props.showInlineButtons && 'flex:1;'};
   @media (max-width: 767px) {
    // flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const SubSectionLink = styled.div`
  display: flex;
  position:relative;
  flex-direction:column;
  align-items:flex-start;
  // flex: ${({ isUpdateMode }) => (isUpdateMode ? 4 : '0 auto')};
  flex: '0 auto';
  ${props => props.showInlineButtons && 'flex:none;'};
  width: 180px;
  margin-right: 20px;
  @media (max-width: 767px) {
    // align-items: normal;
    // margin-bottom: 16px;
    // width: 100%;
  }
`;

/**
 * @description calculates theme configuration
 * @param {Object} props entire props
 * @param {String} button prev/next button
 *
 * @nikhil @swetha Please take care of this. This is a temporary
 * function which calculates back and next button theme configs.
 */
const getThemeConfiguration = (props, button) => {

  const theme = get(props, 'theme.config.theme.global');
  const prevButtonTheme = get(theme, 'prevButtonTheme', { themeConfiguration: {} });
  const nextButtonTheme = get(theme, 'nextButtonTheme', { themeConfiguration: {} });
  const listAddButtonTheme = get(theme, 'listButtonsTheme.listItemAddButtonTheme', { themeConfiguration: {} });

  const colorOverride = get(props, 'theme.config.theme.global.colorOverride', null );
  const cssVariables =  getCSSVariables(props.theme);
  const font = at(cssVariables, ['pageOverride.font', 'global.font']);

  nextButtonTheme.themeConfiguration.font= font?.[0]?.['button-next'] || font?.[1]?.['button-next']
  prevButtonTheme.themeConfiguration.font= font?.[0]?.['button-prev'] || font?.[1]?.['button-prev']
  listAddButtonTheme.themeConfiguration.font= font?.[0]?.['button-list-item-add'] || font?.[1]?.['button-list-item-add']

  let themeConfiguration = null;

  if (colorOverride) {
    themeConfiguration = {
      selectedBgColor: colorOverride.selectedBgColor,
      bgColor: '#ffffff',
      border: `1px solid ${colorOverride.selectedBgColor}`,
      fontColor: '#333333',
    }
  }
  if (button === 'next' && nextButtonTheme && nextButtonTheme.themeConfiguration) {
    themeConfiguration = nextButtonTheme.themeConfiguration
  }

  if (button === 'prev' && prevButtonTheme && prevButtonTheme.themeConfiguration) {
    themeConfiguration = prevButtonTheme.themeConfiguration
  }

  if (button === 'add' && listAddButtonTheme && listAddButtonTheme.themeConfiguration) {
    themeConfiguration = listAddButtonTheme.themeConfiguration
  }

  return themeConfiguration;
}

const ActionButtons = (props) => {
  const {
    pageId,
    actionProps,
    enableContinue,
    handleAuthSubmit,
    handleNextButton,
    handleSectionContinue,
    sectionContinue,
    isList,
    enableAdd,
    handleListAdd,
    handleListSubmit,
    processing,
    currentStatus,
    isLastSection,
    handleBackLink,
    otherParams,
    isUpdateMode,
    prevButtonText,
    nextButtonText,
    nextButtonDescription,
    redirectUrl,
    listId,
    prevBtnScrollTop,
    sectionData,
    questionList,
    listAddButtonLabel,
    isPopUpOpen,
    value,
    currentBreadcrumbTitle,
    ipAddress,
    rootListIndex,
    isNestedList,
    rootListId,
    mode,
    hasErrorActionMessage,
    ...otherProps
  } = props

  const { handleListAction } = useContext(ListContext)

  // Label Config for page with single button
  const getSingleButtonLabel = () => {
		if (processing) return 'PROCESSING...';
		if (nextButtonText) return nextButtonText;
		return buttonLabelMap[pageId]
  };

  const getButtonLabel = () => {
    if (processing) return 'Processing';
    if (nextButtonText) return nextButtonText;
    return 'Continue';
  }

  const isListAddorEditMode = () => {
      if(questionList && Array.isArray(questionList) && questionList.length > 1){
        const listQ = questionList.filter(q => q.display_type === 'list');
        if (listQ.length) {
          const submissionType = get(listQ[0], 'submission_type')
          // has_items will be false for the first list item
          const hasItems = get(listQ[0], 'has_items', false);
          if(submissionType === 'all' || !hasItems){
            return false;
          }
          const childQuestionsCompletedFlag = get(listQ[0], 'child_questions_completed_flag');
          const isEditMode = (mode === 'listEdit' || mode === 'nestedListEdit');
          return !childQuestionsCompletedFlag || isEditMode;
        }
      }
    return false;
  }

  const nextButtonHide = get(otherParams, 'is_next_btn_hidden', false);
  const backButtonHide = get(otherParams, 'is_prev_btn_hidden', false) || isListAddorEditMode();
  const pageLayout = get(props, 'theme.pageLayout', '');
  const theme = get(props, 'theme.config.theme.global');
  const primaryButton = get(props, 'theme.config.theme.global.primaryButton');
  // const showInlineButtons = get(theme, 'showInlineButtons', props.currentStatus==='challenge'?true:false);
  const showInlineButtons = get(theme, 'showInlineButtons', false);
  const mergeActionPageButtons = get(theme, 'mergeActionPageButtons', false);
  const cardPadding = get(props, 'theme.config.theme.components.card.padding');
  const prevButtonTheme = get(theme, 'prevButtonTheme', { themeConfiguration: {} });
  const nextButtonTheme = get(theme, 'nextButtonTheme', { themeConfiguration: {} });
  const listAddButtonTheme = get(theme, 'listButtonsTheme.listItemAddButtonTheme', { themeConfiguration: {} });
  const actionButtonTheme = get(theme, 'actionButtonTheme', { themeConfiguration: {} });
  const pageOverrides=get(props,'theme.config.theme.pageOverrides',{});
  const  maxWidth  = pageOverrides[pageId] &&pageOverrides[pageId].maxWidth
  // eslint-disable-next-line react/destructuring-assignment
  const cssVariables =  getCSSVariables(props.theme);
  const font = at(cssVariables, ['pageOverride.font', 'global.font']);
  const buttonDescriptionTheme = get(props, 'theme.config.theme.global.buttonDescriptionTheme');
  const fullWidthBtnTheme = pageOverrides[pageId] ? pageOverrides[pageId].fullWidthButton : {};
  const marginLeft = get(pageOverrides, `${pageId}.card.margin.left`);
  const mql = window.matchMedia('(forced-colors: active)');
  nextButtonTheme.themeConfiguration.hover.selectedBgColor = (mql.matches)? 'Highlight': '#D9E3E6';
  nextButtonTheme.themeConfiguration.font= font?.[0]?.['button-next'] || font?.[1]?.['button-next']
  prevButtonTheme.themeConfiguration.font= font?.[0]?.['button-prev'] || font?.[1]?.['button-prev']
  actionButtonTheme.themeConfiguration.font= font?.[0]?.['button-action'] || font?.[1]?.['button-action']

  // Handles prev button action
  let prevButtonAction = handleBackLink;
  if (prevBtnScrollTop) prevButtonAction = scrollTop;

  // if(hasErrorActionMessage){
  //   return (
	// 		<>
  //     <PageButton mergeActionPageButtons={ mergeActionPageButtons } cardPadding={ cardPadding } pageLayout={ pageLayout } showInlineButtons = { showInlineButtons } maxWidth = { maxWidth } marginLeft={ marginLeft }name="action-buttons">
  //       <SubSection showInlineButtons = { showInlineButtons } name='subsection'>
  //       <SubSectionButton
  //           name="page-section-button"
  //           id='page-section-button'
  //           isUpdateMode={ isUpdateMode }
  //           showInlineButtons={ showInlineButtons }
  //           themeConfiguration={ getThemeConfiguration(props,'next') }
  //         >
  //           <Button
  //             label = "Reload"
  //             btnType="rectangle"
  //             handleButtonClick={() =>  window.location.reload() }
  //             color={ primaryButton.color }
  //             themeConfiguration={ getThemeConfiguration(props, null) }
  //             { ...otherProps }
  //             { ...prevButtonTheme }
  //             width='100%'
  //             />
  //         </SubSectionButton>
  //       </SubSection>
  //     </PageButton>
	// 		</>
	// 	);
  // }
  // Handles Action Buttons
  if (!isEmpty(actionProps)) {
		return (
			<>
				<ButtonWrapper>
					<Button
						handleButtonClick={ handleSectionContinue }
            actionProps={ actionProps }
            color={ primaryButton.color }
            disabled={ !value ||!sectionContinue|| processing }
            themeConfiguration={ getThemeConfiguration(props, null) }
            { ...otherProps }
            { ...actionButtonTheme }
					/>
				</ButtonWrapper>
			</>
		);
  }

  // Single Button Display Exception Cases
  // Task - https://sureify.atlassian.net/browse/AS-577
  const singleButtonExceptions = () => {
    if (pageId === 'quote' && !backButtonHide) return true;
    return false;
  }

  const getAriaLabel = (buttonLabel) => {
    const pageLabel = currentBreadcrumbTitle || pageId;
    return `${buttonLabel} ${pageLabel}`
  }

  // Configs for handling pages with single button
  if (singleButtonPage(pageId) && !singleButtonExceptions()) {
		return (
			<FullWidthButton name="full-width-button" fullWidthBtnTheme={ fullWidthBtnTheme }>
				<Button
					label={ getSingleButtonLabel() }
          id={ `btn-${toDashCase(getSingleButtonLabel())}` }
          btnType="rectangle"
          color={ primaryButton.color }
          disabled={ !enableContinue || processing || isPopUpOpen }
          { ...nextButtonTheme }
					handleButtonClick={ () => {
						if (pageId === 'login') {
							handleAuthSubmit();
						} else if (pageId === 'review') {
							handleNextButton(pageId);
						} else if(pageId === 'signature' && redirectUrl){
							window.location.replace(redirectUrl)
						} else {
							handleSectionContinue()
						}
          } }
          themeConfiguration={ getThemeConfiguration(props, 'next') }
          description={ nextButtonDescription }
          buttonDescriptionTheme={ buttonDescriptionTheme }
				/>
			</FullWidthButton>
		);
  }

  // Handles List Add Button
  if (listId) {
    // for Nationwide we use 'listAddButtonLabel' that is sent from Back end
    const listActionId = 'list-add-btn';
		return (
      <ListButtonWrapper disabled={ !enableAdd }>
        <Button
          label={ listAddButtonLabel || 'Add' }
          type="subsection"
          disabled={ !enableAdd }
          handleButtonClick={ () => {
            handleListAction('')
            handleListAdd(rootListIndex, isNestedList, listActionId, rootListId)
          } }
          themeConfiguration={ getThemeConfiguration(props, 'add') }
          { ...listAddButtonTheme }
        />
      </ListButtonWrapper>
		);
  }
  if (!nextButtonHide || !backButtonHide || isUpdateMode)
    return (
      <PageButton mergeActionPageButtons={ mergeActionPageButtons } cardPadding={ cardPadding } pageLayout={ pageLayout } showInlineButtons = { showInlineButtons } maxWidth = { maxWidth } marginLeft={ marginLeft }name="action-buttons">
        <SubSection showInlineButtons = { showInlineButtons } name='subsection'>
          {((isUpdateMode && !backButtonHide) || !backButtonHide) ? <SubSectionLink name='link' isUpdateMode={ isUpdateMode }>
            {(isUpdateMode && !backButtonHide) ? (
              <Button
                color='red'
                label='Cancel'
                id='btn-cancel'
                disabled={ processing }
                type='cancel'
                { ...prevButtonTheme }
                handleButtonClick={
                  isList
                    ? () => handleListSubmit('CANCEL_UPDATE')
                    : () => handleSectionContinue('CANCEL_UPDATE', isList)
                }
                themeConfiguration={ getThemeConfiguration(props, 'prev') }
                ariaLabel={ getAriaLabel('cancel') }
              />
            ) : (
              !backButtonHide && (
                <Button
                  shape='rounded'
                  label={ prevButtonText }
                  id={ `btn-prev-${toDashCase(prevButtonText)}` }
                  type='text'
                  disabled={ isPopUpOpen }
                  color={ primaryButton.color }
                  handleButtonClick={ prevButtonAction }
                  { ...prevButtonTheme }
                  themeConfiguration={ getThemeConfiguration(props, 'prev') }
                />
              )
            )}
          </SubSectionLink> : null}
          <SubSectionButton
            name="page-section-button"
            id='page-section-button'
            isUpdateMode={ isUpdateMode }
            showInlineButtons={ showInlineButtons }
            themeConfiguration={ getThemeConfiguration(props,'next') }
          >
              {isUpdateMode ? (
                <Button
                  label='Update'
                  id='btn-update'
                  type='subsection'
                  tabIndex='0'
                  disabled={ !enableContinue || processing }
                  { ...nextButtonTheme }
                  themeConfiguration={ getThemeConfiguration(props, 'next') }
                  handleButtonClick={
                    isList
                      ? () => handleListSubmit('CONFIRM_UPDATE')
                      : () => handleSectionContinue('CONFIRM_UPDATE', isList)
                  }
                  ariaLabel={ getAriaLabel('update') }
                />
              ) : (
                !nextButtonHide && (
                  <Button
                    shape="rectangle"
                    label={ getButtonLabel() }
                    id={ `btn-next-${toDashCase(getButtonLabel())}` }
                    color={ primaryButton.color }
                    themeConfiguration={ getThemeConfiguration(props, null) }
                    handleButtonClick={
                      isList
                        ? () => handleListSubmit('')
                        : () => handleSectionContinue('', false, ipAddress)
                    }
                    width='100%'
                    disabled={ !enableContinue || processing || isPopUpOpen }
                    { ...nextButtonTheme }
                  />
                )
              )}
          </SubSectionButton>
        </SubSection>
      </PageButton>
    );
  return null;
};

const mapStateToProps = (state, props)=> {
  const { isLoading, loadingSection } = getLoaders(state);
  const { otherParams, questionList } = getCurrentQuestionSet(state);
  const getCurrentBreadCrumb = getBreadCrumbInfo(state)
  const listSelector = getListProps(state);
  const { activeSubsectionId, rootListIndex, nestedListIndex } = state.questions;
  const { sectionData } = props
  const sectionIndex = sectionData ?
    sectionData.length -1 :
    props.sectionIndex;

  // If id is not present then takes the path
  const id = get(props, 'match.params.id', null) || get(props,'match.url','').replace('/','')

  // Section / Breadcrumb Level Continue Status
  const { enableContinue: sectionContinue } = getContinueState(state, sectionIndex, activeSubsectionId)
  const enableContinue = getEnableContinueBreadCrumb(state, id, props?.mode, props?.pageId, sectionIndex, undefined, undefined, props?.listState);
  const isPopUpOpen = get(state, 'questions.popupDetails.show', false);

  const currentStatus = get(otherParams, 'current_status', '')
  const showEditPageFlag = get(otherParams, 'show_edit_page_flag', false)
  const prevButtonText = get(otherParams, 'prev_button_text', 'Back');
  const nextButtonText = get(otherParams, 'next_button_text', '');
  const redirectUrl = get(otherParams, 'next_button_redirect_url', '');

  // Config for Button Events (e.g scroll to top on click)
  let prevBtnScrollTop = false;
  const prevButtonEvent = get(otherParams, 'buttons.prev.event');
  if (prevButtonEvent) {
    const { type, scrollTo } = prevButtonEvent;
    if (type === 'scroll' && scrollTo === 'top') {
      prevBtnScrollTop = true;
    }
  }

  // single button description
  const nextButtonDescription = get(otherParams, 'buttons.next.description', '');

  const currentBreadcrumb = getCurrentBreadCrumb(id);
  let total = 0;
  let currentSection = 0;

  if (isPlainObject(currentBreadcrumb) && currentBreadcrumb.section_progress) {
    total = currentBreadcrumb.section_progress.total_sections;
    currentSection = currentBreadcrumb.section_progress.current_section_number;
  }
const ipAddress = get(state, 'meta.ipAddress', '');

  const { isList, listDisplay } = listSelector(sectionIndex);
  const isUpdateMode = showEditPageFlag;
  const isProcessing = () => {
    if (singleButtonPage(props.pageId)) {
      return isLoading
    }
    return isLoading && (loadingSection === sectionIndex);
  }

  return {
      enableContinue,
      sectionContinue,
      isList,
      listDisplay,
      processing: isProcessing(),
      currentStatus,
      isLastSection: total-1 <= currentSection,
      otherParams,
      isUpdateMode,
      prevButtonText,
      nextButtonText,
      nextButtonDescription,
      redirectUrl,
      prevBtnScrollTop,
      questionList,
      isPopUpOpen,
      ipAddress,
      rootListIndex,
      nestedListIndex,
  }
}

const mapDispatchToProps = (dispatch, props)=>{
  const { sectionData, onContinue, questionIds, listId, unsetListEditMode } = props
  const sectionIndex = sectionData ?
    sectionData.length -1 :
    props.sectionIndex;
  return {
    handleAuthSubmit: () => {
			dispatch(submitAuthQuestion());
		},
    handleSectionContinue: (action, isList = false, ipAddress='') => {
      let actionProps = {};
      if (props.actionProps) actionProps = props.actionProps
      if (onContinue) onContinue(sectionIndex);
      dispatch(submitSectionQuestion(sectionIndex, { actionProps, isList, ipAddress }, action));
    },
    handleListAdd: (rootListIndex, isNestedList, listActionId = undefined, rootListId) => {
      dispatch(addListItem(sectionIndex,  null, questionIds, listId, rootListIndex, isNestedList, listActionId, undefined, rootListId ))
      unsetListEditMode()
    },
    handleListSubmit: (action) => {
      dispatch(submitListAnswers(sectionIndex, action, listId));
    },
    handleBackLink: ()=>{
      dispatch(goToPreviousBreadCrumb(sectionIndex))
    },
    handleNextButton: () => {
			dispatch(getNextPage({
				next_page_flag: 1,
				review_completed: true,
			}));
		},
  }
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(ActionButtons));