/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import styled, { withTheme } from 'styled-components';
import GetElementFunc from '../../util/GetElement';
import ListQuestions from '../List'
import CreateScript from '../../util/CreateScript';
import { questionType } from '../../types';
import { rebuildQuestionList } from '../../utils';
import { setListRelation } from '../../redux/actions/meta';
import ReflexiveCard from '../UIElements/Card/ReflexiveCard';
import NList from '../../n-list'
import './styles.css'

/* Suspense fallback to be provided on lazy loading the below components */
// const NList = lazy(() => import('../../n-list'));
// const ReflexiveCard = lazy(() => import('../UIElements/Card/ReflexiveCard'));
// const GetElementFunc = lazy(() => import('../../util/GetElement'));
// const ListQuestions = lazy(() => import('../List'));

const Wrapper = styled.div`
  ${({ questionTypeEl })=> questionTypeEl!== 'document' && `
  display: flex;
  flex-direction: row;
  align-items: flex-end;`
}

  ${({ childNodeLength, activeSubsectionId }) => (activeSubsectionId === 'completed' && childNodeLength >= 1) ?
	`	justify-content: space-evenly;
		// gap:60px; //flexbox gap is not supported fewer versions in safari
		@media (min-width: 320px) and (max-width: 767px){
			flex-direction : column;
			align-items: center;
			// gap: 0;
			& div:first-child {
				// margin-right: 0;
			}
		}
		@media (min-width: 768px) and (max-width: 1023px){
			flex-wrap: wrap;
			// gap: 0;
			> * + * {
				margin-left: 20px;
			}
			// > *:last-child{
			// 	margin-left: 0;
			// }
		}
	`:
	`
		// justify-content: flex-left;
	`
  }

  ${({ childNodeLength, activeSubsectionId }) => (childNodeLength > 1 && activeSubsectionId !== 'completed') && `
		& div:first-child {
			margin-left: 0;
			margin-right: 2px;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}
		> * {
			overflow: hidden;
		}
	`}

	${({ childNodeLength }) => (childNodeLength > 3) &&
		`
			margin-bottom: 35px;
			> * {
				overflow: unset;
			}
		`
	}
`;

const SubHeader = styled.p`
${({ id }) => !id &&  'display : none'}
margin: 10px 0 10px -50px;
font-family: Roboto-Bold;
@media only screen and (max-width: 768px) {
	margin: 10px 0;
}
`;

const InlineQuestion = styled.div`
padding-left: 12px;
`

const isRequired = (question) => {
  return get(question, 'validations.required.value', false);
};

const skipUpdate = (currentRelatives, question) => {
	if (!currentRelatives) {
		return false;
	}

	const pId = get(question, 'properties.parent_list_id', '');
	const qId = question.question_id.split('_$ureify_')[1];

	let hasMatch = false;

  const parents = Object.keys(currentRelatives);
  for (let i = 0; i < parents.length; i += 1) {
    if (parents[i] === qId && currentRelatives[parents[i]] === pId) {
      hasMatch = true;
      break;
    }
  }

  return hasMatch;
}

const questionRenderer = (
	questionList,
	sectionIndex,
	reflexLevel,
	overrideReflexLevel,
	changeMode,
	updateListRelation,
	activeSubsectionId,
	currentRelatives,
	showModalQuestions = false,
	listEditQId,
	listParentId
) => {
	return questionList.map((item, index) => {
		if(!showModalQuestions && item.isModalQuestion){
			return null
		}
		if (item.submission_type === 'all' && item.display_type === 'list') {
			return <NList listQ={ item }/>
		}
		if (isArray(item)) {
			return (
					<ReflexiveCard
						key={ `${sectionIndex}--${reflexLevel + 1}--${index}` }
						questionArray={ item }
						parentQuestionId={ get(item[0], 'question_id', '') }
						reflexLevel={ overrideReflexLevel ? 0 : (reflexLevel + 1) }
						view={ get(item[0], 'properties.view', '') }
						hideWrapper={ get(item[0], 'properties.hideReflexWrapper', false) }
						activeSubsectionId = { activeSubsectionId }
						listEditQId = { listEditQId }
					>
						{questionRenderer(item, sectionIndex, reflexLevel + 1, overrideReflexLevel, changeMode, updateListRelation, activeSubsectionId, currentRelatives)}
					</ReflexiveCard>
			);
		}
		if(!item.is_hidden && item.display_type === 'list') {
			return (
					<ListQuestions
						key={ item.question_id }
						sectionIndex={ sectionIndex }
						questionObj={ item }
						reflexLevel={ reflexLevel }
						listId={ item.question_id }
						listAddButtonLabel={ item.list_add_button_label }
						changeMode={ changeMode }
						originalQuestions={ item.child_questions_completed_flag ? item.questions : item.original_questions }
						listDisplay={ item.child_questions_completed_flag }
						isReview = { overrideReflexLevel }
					/>
			)
		}
		if (!item.display_type) {
			let nestedItems = Object.values(item);
			nestedItems = nestedItems.filter(i => i && i.display_type)
			if(nestedItems.length) {
				return (
						<ReflexiveCard
							key={ `benf--${sectionIndex}--${reflexLevel + 1}--${index}` }
							questionArray={ nestedItems }
							reflexLevel={ reflexLevel + 1 }
						>
							{questionRenderer(Object.values(nestedItems), sectionIndex, reflexLevel + 1, changeMode, updateListRelation, activeSubsectionId, currentRelatives)}
						</ReflexiveCard>
				)
			}
		}
		if(!item.is_hidden) {
			if (item?.question_id?.toString().indexOf('_$ureify_') > -1 && !skipUpdate(currentRelatives, item)) {
				updateListRelation(item, activeSubsectionId);
			}
			if(item.question_type === 'html_template') {
				return <CreateScript key ={ `${sectionIndex}--${reflexLevel + 1}--${index}` } item={ item } sectionIndex={ sectionIndex } required={ isRequired(item) }/>
			}
			let qItem
			if(item.question_id === 'DefaultQuoteAmounts'){
				qItem={ ...item,className:`${process.env.REACT_APP_CARRIER_ID}-radio-group-wrapper` }
			}
			else{
				qItem=item
			}
			if(qItem?.sub_disclosure_id){
				console.debug(`SubDisclosureId displayed for ${qItem?.reflexive_index} nested level`);
			}
			return (
				<Fragment key={ `${sectionIndex}--${reflexLevel + 1}--${index}` }>
					{(qItem?.mras_parent_question_type==='multiselection' || qItem?.sub_disclosure_id) && /* questionList?.length>=2 && */ [1,2,3].includes(qItem?.reflexive_index) && <SubHeader id= { qItem?.disclosure_id }>{qItem?.disclosure_id}</SubHeader>}
						<GetElementFunc
							questionObj={ qItem }
							sectionIndex={ sectionIndex }
							required={ isRequired(item) }
							isEditMode={ false }
							listParentId={listParentId || ''}
							listIndex={ null }
							changeMode={ changeMode }
						/>
				</Fragment>
			);
		}
		return null;
	});
};

export function DisplayInlineQuestions ({
	activeSubsectionId,
	changeMode,
	elements,
	index,
	listParentId,
	orientationType,
	orientationValue,
	overrideReflexLevel,
	pageId,
	pageOverrides,
	screenViewport,
	sectionIndex,
	updatedQuestionList,
})
 {
	if( orientationType === 'rows' && orientationValue > 1) {
		let inlineCategory = '';
			while(index < updatedQuestionList.length){
				const isInline = get(updatedQuestionList[index], 'properties.inline.value', false);
				let endIndex = index+1;
				if(isInline && ((screenViewport !== 'mobile') || !(pageOverrides[pageId] && pageOverrides[pageId].skip2Column))){
					endIndex = index+orientationValue;
					inlineCategory = get(updatedQuestionList[index], 'properties.category', '');
				}
				const elementQuestionType = updatedQuestionList.slice(index, endIndex)[0].question_type;
				const element = (
					<>
						{inlineCategory && <InlineQuestion> {inlineCategory} </InlineQuestion>}
						<Wrapper
							activeSubsectionId={ activeSubsectionId }
							childNodeLength={ endIndex - index }
							className= 'abcjkdsf'
							questionTypeEl = { elementQuestionType }
						>
							{questionRenderer(
								updatedQuestionList.slice(index, endIndex),
								sectionIndex,
								0,
								overrideReflexLevel,
								changeMode,
								undefined,
								undefined,
								undefined,
								undefined,
								undefined,
								listParentId
							)}
						</Wrapper>
					</>
				)
				elements.push(element);
				index = endIndex;
				inlineCategory = ''
		}
		return (
			<>
				{elements}
			</>
		);
	}
}

const QuestionRenderer = ({ questionList, sectionIndex = 0, pageId, rebuild, updateListRelation, activeSubsectionId, changeMode, currentRelatives, screenViewport, theme, showModalQuestions, listEditQId }) => {
	const {
		config: {
			theme: {
				pageOverrides,
			},
		},
	} = theme;
  if (questionList.length) {
		if (rebuild === undefined || rebuild === true) {
			const updatedQuestionList = rebuildQuestionList(
				[...cloneDeep(questionList)],
				questionList[0].reflexive_index,
			);
			const overrideReflexLevel = pageId === 'quotereview' || pageId === 'review';
			/**
			 * Group the inline questions if
			 * Orientation is row
			 * Orientation value is greater than 1
			 */
			const orientationType = get(updatedQuestionList[0], 'properties.orientation.type', '');
			const orientationValue = get(updatedQuestionList[0], 'properties.orientation.value', 1);
			const elements = [];
			const index = 0;
			const DisplayInlineQuestionsProps = {
				updatedQuestionList,
				orientationType,
				orientationValue,
				elements,
				index,
				screenViewport,
				pageOverrides,
				pageId,
				activeSubsectionId,
				sectionIndex,
				overrideReflexLevel,
				changeMode,
			}
			if(orientationType === 'rows')
				return <DisplayInlineQuestions { ...DisplayInlineQuestionsProps }/>

				return (
					<>
						{questionRenderer(updatedQuestionList, sectionIndex, 0, overrideReflexLevel, changeMode, updateListRelation, activeSubsectionId, currentRelatives, showModalQuestions, listEditQId)}
					</>
				);
		}

		return (
			<>
				{questionRenderer(questionList, sectionIndex, 0, false, changeMode, updateListRelation, activeSubsectionId, currentRelatives, showModalQuestions, listEditQId)}
			</>
		);
  }
  return null;
};

QuestionRenderer.propTypes = {
  questionList: PropTypes.arrayOf(questionType).isRequired,
  sectionIndex: PropTypes.number.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default withTheme(connect(({ questions, meta }) => {
	return {
		activeSubsectionId: questions.activeSubsectionId,
		currentRelatives: meta.listRelation[questions.activeSubsectionId],
		popupDetails: get(questions, 'popupDetails', {}),
		screenViewport: meta.screenViewport,
		listEditQId: questions?.listEditQId,
	}
}, (dispatch) => (
	{
		updateListRelation: (question, activeSubsectionId)  => {
			dispatch(setListRelation({
				question,
				activeSubsectionId,
			}));
		},
	}
))(QuestionRenderer));
