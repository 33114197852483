/* eslint consistent-return: off */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import at from 'lodash/at';
import get from 'lodash/get';
import { flattenDeep, uniq } from 'lodash';
import { getListProps } from '../../redux/selector/listQuestion';
import ListQuestionAction from '../UIElements/Card/ListQuestionAction';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import GetElementFunc from '../../util/GetElement';
import TextElement from '../UIElements/TextElement';
// eslint-disable-next-line import/no-cycle
import { insertReflexiveQuestions } from '../../utils';
import ScrollToSection from '../../ScrollToSection';
import { getCSSVariables } from '../../cruxComponents/utils';
// eslint-disable-next-line import/no-cycle
import ListQuestionCard from '../UIElements/Card/ListQuestionCard';
import {
	getQuestionStatus, listDependantQHelper,
} from '../../redux/actions/listQuestionHelper';
import { DisplayInlineQuestions } from '../QuestionRenderer';
import { ListContext } from './ListContext';
/* Suspense fallback to be provided on lazy loading the below component */
// const ListQuestionCard = lazy(() => import('../UIElements/Card/ListQuestionCard'));


const ListWrapper = styled.div`
  background: rgba(226, 226, 226, 1);
  ${({ reflexLevel }) =>
    reflexLevel < 1 &&
    `
    padding: 20px;
    margin: 0% -5.5% 5.5% -5.5%;
  `}
  margin-bottom: 30px;
  ${({ margin })=>
  `
    margin:${ margin?.[0]?.['listQuestions-listWrapper'] || margin?.[1]?.['listQuestions-listWrapper']};
  `}
`;

const ListQuestionWrapper = styled.div`
  background: rgba(255, 255, 255, 1);
  ${({ reflexLevel }) =>
    reflexLevel < 1 &&
    `
    padding: 15px 0 15px 80px;
  `}
  border-radius:${({ themeConfiguration }) =>
    themeConfiguration ?.borderRadius ? themeConfiguration ?.borderRadius : '8px'};
  @media only screen and (max-width: 1025px) {
    padding: 15px;
  }
  @media only screen and (max-width: 767px) {
    padding: 10px;
    padding-left: 25px;
  }
  div[id*="radio-"] {
    margin-left: 4px;
  }
  div[role=radiogroup] {
    margin-left: 4px;
  }
`;

const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 20px;
	@media only screen and (max-width: 767px) {
		justify-content: center;
		margin-right: 0;
	}
`;

const FakeNestedListRef = styled.div.attrs({
  'tabIndex':'0',
})`
  position: absolute;
  left: -9999px;
`;

// const CloseAction = styled.div`
//   @media only screen and (min-width: 768px) {
//     position: absolute;
//     right: 90px;
//     width: 40px;
//     height: 50px;
//     display: flex;
//     justify-content: center;
//     align-items: baseline;
//   }
// `;

const isRequired = (question) => {
  return get(question, 'validations.required.value', false);
};

/**
 * @description returns an array of [groupquestion, listquestion]
 * @param questionObject
 * @returns {[*, *]|*[]}
 */
const getListQuestionTitles = (questionObject) => {
  try {
    const groupQ = questionObject;
    const listQText = get(questionObject, 'questions[0].question_text', '');
    return [
      {
        ...groupQ,
        question_text: groupQ.question_text || listQText, // this is a temporary fix,
        // the backend is not sending question text for group question in this case.
      },
      // Commenting sending list question
      // listQ
    ];
  } catch (e) {
    /* eslint-disable no-console */
    console.error('List question assumption failed.', e);
    return [];
  }
};

const getValidations = (listQuestions, curBreadcrumbSections) => {
  return listQuestions
  .filter((q) => (((!q?.is_hidden && get(q, 'validations.required.value', false)) ||
                  q?.validations?.relative) && listDependantQHelper(q, curBreadcrumbSections)
                 ),
  )
  .map((q) => q.question_id);
}

const getRequiredListQIds = (reflexiveListQ, curBreadcrumbSections) => {
  let nestedListQIds = reflexiveListQ.filter( q => q?.child_questions && q?.child_questions_on).map(q => q?.questions)
  nestedListQIds = flattenDeep(nestedListQIds);
  nestedListQIds = nestedListQIds.filter(q => q?.question_type !== 'group');
  const requiredListQIds =  [
    ...getValidations(reflexiveListQ.filter(q => q?.question_type!== 'group'), curBreadcrumbSections),
    ...getValidations(nestedListQIds, curBreadcrumbSections),
  ]
  return uniq(requiredListQIds)
}

function isNumeric(str) {
  if (typeof str !== 'string') return !Number.isNaN(str)
  return !Number.isNaN(str) &&
         !Number.isNaN(parseFloat(str))
}

export function fetchDropdownResponseOptionsMap(stateDropdownRespOptions = []) {
	let dropdownResponseOptionsMap = {}
  const dropdownResponseOptionsQIds = []
	const dropdownRespOptions = stateDropdownRespOptions
	for(let i = 0 ; i < dropdownRespOptions.length ; i += 1){
		const eachQ = dropdownRespOptions[i]
			dropdownResponseOptionsMap = {
				...dropdownResponseOptionsMap,
				[eachQ.question_id]: {
					response_options: eachQ?.response_options,
					child_questions_on: eachQ?.child_questions_on
				}
			}
			dropdownResponseOptionsQIds.push(eachQ?.question_id)
    }
		return {dropdownResponseOptionsMap, dropdownResponseOptionsQIds}
}

const ListQuestions = ({
  sectionIndex,
  listDisplay,
  addButtonText,
  originalQuestions,
  questionObj,
  changeMode,
  listId,
  reflexLevel,
  isReview,
  listAddButtonLabel,
  theme,
  isReflexive,
  lastFocusedElementId,
  rootListIndex,
  isNestedList,
  rootListId,
  rListDisplay,
  activeSubsectionId,
  screenViewport,
  curBreadcrumbSections,
 }) => {

  const nestedListIndexRef = useRef(-1);
  const nestedListRef = useRef();
  const questionObjL = questionObj?.questions?.length;

  const { listState, handleListAction } = useContext(ListContext)

  const _addButtonText = get(questionObj, 'add_button_text', addButtonText || 'Add Record');
  const listParentId = get(questionObj, 'question_id', '');
  const editModeParam = get(questionObj, 'edit_mode', false);
  const editModeIndex = get(questionObj, 'edit_mode_index', null);
  const isCancelBtnDisabled = get(questionObj, 'is_cancel_btn_disabled', false);
  // hide close button for the first list item
  const displayCloseAction = get(questionObj, 'has_items', false);
  const maxListItems = get(questionObj, 'validations.max_items.value', 99);
  const isEditable = get(questionObj, 'is_editable', true);
  const parentQAnsMap = get(questionObj, 'parent_question_answer.questions', []);
  let curListParentQs; let curListParentQId
  if(parentQAnsMap?.length){
    curListParentQs = parentQAnsMap.map((eachObjMap) => {
      if(!eachObjMap?.answer?.some(isNumeric)){
        return eachObjMap?.question_id
      }
    })
    curListParentQs = curListParentQs.filter(eachParentQ => eachParentQ)
    if(curListParentQs?.length > 0)
      curListParentQId = curListParentQs[0]
  } else if(questionObj?.disclosure_id)
      curListParentQId = get(questionObj, 'disclosure_id', '');
    else if(questionObj?.linked_parent_question_id)
      curListParentQId = get(questionObj, 'linked_parent_question_id', '');

  let reflexiveListQ = [];
  if(originalQuestions.length > 1 && Array.isArray(originalQuestions[0])) {
    reflexiveListQ = originalQuestions.map(q => insertReflexiveQuestions(q));
    reflexiveListQ = flattenDeep(reflexiveListQ);
  } else {
    reflexiveListQ = insertReflexiveQuestions(originalQuestions);
  }

  const currentSecData = curBreadcrumbSections?.[sectionIndex]
  if(!!currentSecData && currentSecData?.dropdownResponseOptions){
		const {dropdownResponseOptionsMap, dropdownResponseOptionsQIds} = fetchDropdownResponseOptionsMap(currentSecData?.dropdownResponseOptions)
		for(let i = 0 ; i < reflexiveListQ.length ; i += 1){
			const eachRQ = reflexiveListQ[i];
			if(dropdownResponseOptionsQIds?.length && dropdownResponseOptionsQIds?.includes(eachRQ.question_id)){
				reflexiveListQ[i] = {
					...eachRQ,
					response_options: dropdownResponseOptionsMap[eachRQ?.question_id]?.response_options,
          child_questions_on: dropdownResponseOptionsMap[eachRQ?.question_id]?.child_questions_on,
				}
			}
		}
	}

  const listQIds = reflexiveListQ.map((q) => q.question_id);
  const requiredListQIds = getRequiredListQIds(reflexiveListQ, curBreadcrumbSections);
  const listError = getQuestionStatus(reflexiveListQ)

  const {
    pageId,
    config: {
      theme: {
        pageOverrides,
        components: {
          card: { themeConfiguration },
        },
      },
    },
  } = theme;

  let listWrapperElement; let listWrapperPEl
  const listActionIds = ['list-cancel-btn', 'list-delete-btn', 'list-add-btn'];
  const actionIdRegex = new RegExp(`^(${listActionIds.join('|')})`, 'i');

  const handleResize = () => {
    listWrapperElement = document.querySelector(`div[id*='list-wrapper-id-${listId}']`);
    listWrapperPEl = listWrapperElement?.parentElement
    if(listWrapperPEl && reflexLevel <= 0){
      const paddingL = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-left');
      const paddingR = window?.getComputedStyle(listWrapperPEl, null)?.getPropertyValue('padding-right');
      listWrapperElement.style.marginLeft = `-${paddingL}`;
      listWrapperElement.style.marginRight = `-${paddingR}`;
    }
  }

  useEffect(() => {
    if(questionObjL > nestedListIndexRef.current && !isNestedList){
      nestedListIndexRef.current = questionObjL;
    }
  }, [questionObjL]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize, false);
  }, [listWrapperPEl?.clientWidth])

  useEffect(() => {
    if(!listDisplay){
      handleListAction({
        ...listState,
        listId,
        listParentQId: curListParentQId,
        curListParentQs,
        action: 'newListItemRender',
      });
    }
    return () => {
      const getAgentDataButton = document.querySelector('button[id*=\'get-agent-data-button\']');
      if(!getAgentDataButton && !questionObj.child_questions_completed_flag) handleListAction('')
    };
  }, [])

  useEffect(() => {
    if(actionIdRegex.test(lastFocusedElementId)){
      const element = document.querySelector('div[name=\'list-item-card-wrapper\'] > div:nth-last-child(2) > div > div[name=\'header\'] > span:nth-child(2) > button');
      if(element) {
        	element.focus();
      }
    }
    if (isNestedList && nestedListRef && nestedListRef.current && lastFocusedElementId === 'add-list-item-btn') {
      if (typeof nestedListRef.current.focus === 'function') {
        nestedListRef.current.focus();
      }
    }
	}, []);
  // CSS Variables
  const cssVariables = getCSSVariables(theme);
  const margin = at(cssVariables, ['pageOverride.margin', 'global.margin']);
  const _margin = margin?.[0]?.['listQuestions-listWrapper'] || margin?.[1]?.['listQuestions-listWrapper'];
  if(margin?.[0]?.['listQuestions-listWrapper']){
    const shortHandMargin = ['0px', '0px'];
    if(_margin) shortHandMargin.push(_margin.split(' ')[2])
    margin[0]['listQuestions-listWrapper'] = shortHandMargin.join(' ');
  }
  if(margin?.[1]?.['listQuestions-listWrapper']){
    const shortHandMargin = ['0px', '0px'];
    if(_margin) shortHandMargin.push(_margin.split(' ')[2])
    margin[1]['listQuestions-listWrapper'] = shortHandMargin.join(' ');
  }

  let inlineQs= { isDisplayInline: false }

  if (listDisplay) {
    return (
      <ListQuestionCard
        name="list-question-card"
        sectionIndex={ sectionIndex }
        questionList={ [questionObj] }
        addButtonText={ _addButtonText }
        changeMode={ changeMode }
        listId={ listId }
        reflexLevel={ reflexLevel }
        isReview={ isReview }
        listParentId={ listParentId }
        requiredListQIds={ requiredListQIds }
        maxListItems = { maxListItems }
        listDisplay = { listDisplay }
        rootListId = { !isNestedList ? listParentId : rootListId }
        rootListIndex = { rootListIndex }
        rListDisplay = { rListDisplay }
        isNestedList = { isNestedList }
        editModeParam = { editModeParam }
        editModeIndex = { editModeIndex }
        isEditable = { isEditable }
        isCancelBtnDisabled={ isCancelBtnDisabled }
        listParentQId = { curListParentQId }
        curListParentQs = { curListParentQs }
        curBreadcrumbSections = { curBreadcrumbSections }
      />
    );
  }
  return (
    <>
      {getListQuestionTitles(questionObj).map((q) => {
        return (
          <>
            <TextElement
              key={ q.question_id }
              styleType="questions_group" // backend should send this evetually
              text={ q.question_text }
              sectionIndex={ sectionIndex }
            />
            <TextElement
              key={ q.question_description }
              styleType="list"
              text={ q.question_description }
              sectionIndex={ sectionIndex }
            />
          </>
          )
        })
      }
      <ScrollToSection skipAutoScroll={ isReflexive }/>
    <ListWrapper margin={ margin } id={ `list-wrapper-id-${listId}` } name='list-wrapper' reflexLevel={ reflexLevel }>
        <ListQuestionWrapper name='list-question-wrapper' reflexLevel={ reflexLevel } themeConfiguration={ themeConfiguration }>
          {isNestedList  && editModeParam && <FakeNestedListRef ref={ nestedListRef } /> }

          {/* { !listDisplay && displayCloseAction && (
              <CloseAction name='close-action'>
                <ListQuestionAction closeButton sectionIndex={ sectionIndex } listFormQuestions={ reflexiveListQ } listId={ listId }/>
              </CloseAction>
          ) } */}
          { reflexiveListQ.map((q) => {

              if (typeof q.response === 'string') q.response = q.response.trim();

              if(!q?.properties?.inline?.value)
                inlineQs= { isDisplayInline: false, dislayInlineIdx: -1 }

              if(inlineQs.isDisplayInline) return;

              if(q?.display_type === 'questions_group' && q?.properties?.orientation?.value){

                inlineQs= { isDisplayInline: true }

                const updatedQuestionList = insertReflexiveQuestions([q]).filter(ques => ques.display_type!=='list' && (ques?.properties?.inline?.value || ques?.properties?.orientation?.value));

			          const index = 0;
                const elements = [];
                const orientationType = get(q, 'properties.orientation.type', '');
			          const orientationValue = get(q, 'properties.orientation.value', 1);
                const overrideReflexLevel = isReview;
                const displayInlineQuestionsProps = {
                  activeSubsectionId,
                  changeMode,
                  elements,
                  index,
                  listParentId,
                  orientationType,
                  orientationValue,
                  overrideReflexLevel,
                  pageId,
                  pageOverrides,
                  sectionIndex,
                  screenViewport,
                  updatedQuestionList,
                }

                return <DisplayInlineQuestions { ...displayInlineQuestionsProps }/>
              }
              if (q.display_type === 'list'){
                return (
                  <ListQuestions
                    key={ q.question_id }
                    sectionIndex={ sectionIndex }
                    questionObj={ q }
                    reflexLevel={ 0 }
                    listId={ q.question_id }
                    listAddButtonLabel={ q.list_add_button_label }
                    changeMode={ changeMode }
                    originalQuestions={ q.child_questions_completed_flag ? q.questions :  q.original_questions }
                    listDisplay={ q.child_questions_completed_flag }
                    isReview = { isReview }
                    theme = { theme }
                    isNestedList
                    rootListIndex = { nestedListIndexRef.current }
                    rootListId = { listParentId }
                    // rootListIndex={ listIndex }
                  />
                );
              }

              return (
                <GetElementFunc
                  questionObj={ q }
                  sectionIndex={ sectionIndex }
                  required={ isRequired(q) }
                  isEditMode={ false }
                  listParentId={ listParentId }
                  listIndex={ null }
                  rootListIndex = { rootListIndex }
                  rootListId = { rootListId }
                  selectedOptions={ (q.question_type==='singleselection' && questionObj?.BH_Residence_Travel_Countries) || undefined }
                />
              )
            })
          }
          <ActionWrapper name="action-wrapper">
            { !listDisplay && displayCloseAction && (
                  <ListQuestionAction closeButton sectionIndex={ sectionIndex } listFormQuestions={ reflexiveListQ } listId={ listId } rootListIndex = { rootListIndex || nestedListIndexRef }/>
            )}
          <ListQuestionAction
            sectionIndex={ sectionIndex }
            listQIds={ listQIds }
            requiredListQIds={ requiredListQIds }
            listId={ listId }
            listAddButtonLabel={ listAddButtonLabel }
            isNestedList = { isNestedList }
            rootListIndex = { rootListIndex || nestedListIndexRef }
            listError={ listError }
            rootListId = { rootListId }
          />
          </ActionWrapper>
        </ListQuestionWrapper>
      </ListWrapper>
    </>
  );
};

function mapStateToProps(state, props) {
  const { questions: { sections, activeSubsectionId } } = state;
  const listSelector = getListProps(state);
  const { addButtonText } = listSelector(props.sectionIndex);
  const isReflexive = get(state, 'questions.isReflexive', false);
  const lastFocusedElementId = get(state, 'questions.lastFocusedElementId', '');
  const nestedListIndex = get(state, 'questions', null)
  const listQMap = get(state, 'questions.listQMap', {})

  let curBreadcrumbSections = [];
  if (sections) {
    const { sectionIndex } = props;
    if (activeSubsectionId && sectionIndex !== undefined) {
      curBreadcrumbSections = sections[activeSubsectionId]
    }
  }
  return {
    addButtonText,
    isReflexive,
    lastFocusedElementId,
    nestedListIndex,
    listQMap,
    activeSubsectionId: state?.questions?.activeSubsectionId,
    screenViewport: state.meta.screenViewport,
    curBreadcrumbSections,
  };
}
export default withTheme(connect(mapStateToProps, null)(ListQuestions));
