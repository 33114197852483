import styled from 'styled-components';

export const PdfWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin-right: 0;
        width: 100%;
    }

    @media only screen and (min-width: 481px) and (max-width: 767px) {
        margin-right: 1px;
        width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-right: 20px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const PageLabel = styled.p`
    margin-top: 20px;
`;

export const PdfDisplayWrapper = styled.div`
    max-width: 1100px;
    max-height: 800px;
    overflow: scroll;
    .rpv-core__viewer > div[data-testid=core__inner-container] > div[data-testid=core__inner-pages] {
        overflow: inherit !important;
        >div.rpv-core__inner-page >div.rpv-core__page-layer{
            overflow: hidden;
        }
    }
    @media only screen and (min-width: 768px) {
        canvas {
            transform: scale(1) !important;
            transform-origin: left-top !important;
            width: 95% !important;
            height: auto !important;
        }
    }
`;